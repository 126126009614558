import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

function ScrollData({
  Items,
  Title,
  clearAllWatchHistory,
  deleteItem,
  purgeItem,
  handlePurge
}) {
  const clearButtonStyle = {
    position: "absolute",
    width: "max-content",
    height: "30px",
    left: "30px",
    backgroundColor: "#FA5038",
    color: "white",
    borderRadius: "12px",
  };





  return Items.length ? (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {clearAllWatchHistory && (
          <button
            style={clearButtonStyle}
            onClick={() => clearAllWatchHistory()}
          >
            Clear
          </button>
        )}
        <h1 className="text-white mt-2">{Title} </h1>
      </div>

      <div
        className="scroll-container"
        style={{
          overflowX: "auto",
          whiteSpace: "nowrap",
          height: "200px",
        }}
      >
        {Items.map((item, index) => (
          <div
            key={index}
            style={{
              height: "100%",
              position: "relative",
              display: "inline-block",
              marginInline: "6px",
            }}
          >
            <p
              style={{
                position: "absolute",
                bottom: "-15px",
                fontWeight: "900",
                color: "red",
                backgroundColor: "black",
                paddingInline: "3px",
                borderRadius: "10px",
                marginInline: "50%",
              }}
            >
              {item.path[0].split("/")[1]}
            </p>

            <Image
              style={{
                height: "100%",
                width: "auto",
                borderRadius: "10px",
              }}
              variant="top"
              src={item.img}
            />
            <Link
              to={"/user/player"}
              state={{
                url: item.path[0],
                title: item.title,
                overview: item.overview,
                genre: item.genreID,
                rating: item.rating,
                Items: Items,
              }}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                opacity: "0.8",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                className="bi bi-play-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
              </svg>
            </Link>

            {/* Remove From Watch History pending Implementation */}

            {deleteItem && (
              <button
                style={{
                  position: "absolute",
                  display: "flex",
                  top: "5px",
                  left: "35px",
                  fontWeight: "500",
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: "18px",
                  marginInline: "50%",
                  width: "28px",
                  height: "28px",
                  justifyContent: "center",
                  alignItems: "top",
                }}
                onClick={() => deleteItem(item.path[0].split("/")[2])}
              >
                x
              </button>
            )}

            {purgeItem && (
              <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="25" 
              height="25" 
              fill="red" 
              className="bi bi-trash3-fill purge-button" 
              viewBox="0 0 16 16"
              onClick={() => {handlePurge(item)}}
              >
                
              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
            </svg>
            )}
          </div>
        ))}
      </div>
      <div
        className="mb-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-left-circle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-right-circle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
        </svg>
      </div>
    </>
  ) : null;
}

export default ScrollData;
