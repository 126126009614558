import React, {useState, createContext, useEffect} from "react";
export const StateContext = createContext();



export const StateContextProvider = ({children}) => {
    const  [isLoading, setIsLoading]=useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial state based on screen width
    const [isSetup, setIsSetup] = useState(true);
    const [movieDatabase, setMovieDatabase] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [otpVisible, setOtpVisible] = useState(false);

      // useEffect to track window resize and update isMobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set to true if window width <= 768px
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    return(
        <StateContext.Provider value={{isLoading, setIsLoading, otpVisible, setOtpVisible, searchResults, setSearchResults, searchTerm, setSearchTerm, isMobile, setIsMobile, isSetup, setIsSetup, movieDatabase, setMovieDatabase}}>
            {children}
        </StateContext.Provider>
    )
}