import axios from "axios";

export const purgeItemFunction = async (item, user) => {
    const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/mongo/purgeItem`,
        {
          headers: {
            "x-access-token": user.accessToken,
            "Content-Type": "application/json",
          },
          data: { item },
        }
      );
      return response;
    }