import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import { Badge } from "react-bootstrap";

function ShowBlocks({finalData, getEpisode}) {
  return (
    <Container fluid>
      <hr className="hr" style={{ color: "white" }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1 className="text-white mt-2">Shows</h1>
      </div>
      <Row style={{ display: "flex", justifyContent: "center",  }}>
        {finalData.map((item, index) => (
          <Carousel
            className="col-md-2 col-sm-12"
            style={{ width: "50vh", height: "80vh", objectFit: "cover" }}
            key={index}
          >
            {item.path.map((url, episode) => (
              <Carousel.Item
                key={episode}
                style={{ justifyContent: "center", alignItems: "top" }}
              >
                <hr className="hr" style={{ color: "white" }} />

                <div className="image-container">
                  <Image
                    src={item.img}
                    alt="Movie_Poster"
                    style={{
                      height: "75vh",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <Carousel.Caption>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link
                      to={"/user/player"}
                      state={{
                        url: url,
                        title: item.title,
                        path: item.path,
                        episode: getEpisode(url),
                        summary:item.overview,
                        rating:item.rating,
                        genres:item.genreID,
                        year:new Date(item.premiered).getFullYear(),
                      }}
                    >
                      <Button style={{ width: "300px" }} variant="primary">
                        <strong>{item.title} </strong>
                        <strong>
                          {getEpisode(url)}
                          <br />
                          Watch Now
                        </strong>
                        <br />
                        <Badge bg="secondary">
                          {item.path.length} Episodes
                        </Badge>
                        {/* <strong>{`${item.title} Episode ${episode + 1}`}<br />Watch Now</strong><br /><Badge bg="secondary">{item.path.length} Episodes</Badge> */}
                      </Button>
                    </Link>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        ))}
      </Row>
    </Container>
  );
}

export default ShowBlocks;
