import React from "react";
import "../App.css";
import { Link, useLocation } from "react-router-dom";
import SearchComponent from "./SearchBar";
import { useAuthContext } from "../hooks/useAuthContext.js";
// import { useStateContext } from "../hooks/StateContextProvider.js";

function Header() {
  const { user } = useAuthContext();
  // const { setIsLoading } = useStateContext();
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const variant = isLoginPage ? "text-warning" : "text-success";

  // const handleLogout = () => {
  //   setIsLoading(false);
  //   localStorage.removeItem("user");
  //   localStorage.removeItem("tokenExpiration");
  //   dispatch({ type: "LOGOUT" });
  // };

  return (
    <div className="App">
      <header className="header container-fluid text-center">
        {/* d-flex justify-content-center text-white  */}
        <div className="row text-center align-items-start title-bar">
          <div className="col">
            <a href="/">
              <img
                src="/Tsvg.svg"
                className="titlebar-logo-image"
                alt="image_missing"
              />
              <button className="logo-text">
                <strong>MS-Media</strong>
              </button>
            </a>
          </div>
          {user ? (
            <div className="col btn-group">
              {/* <div className="col">
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => handleLogout()}
                >
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                  <strong>Logout</strong>
                </button>
              </div> */}
              <div className="col">
                <p id="logout-timer" className="text-white"></p>
              </div>
            </div>
          ) : null}
        </div>

        {user ? (
          <div className="row navigation-panel-mobile">
            <div>
              <div className="btn-group btn-group-lg">
                <Link to="/user/shows" className="btn btn-primary">
                  <strong>Shows</strong>
                </Link>
                <Link to="/user/allmovies" className="btn btn-primary">
                  <strong>Movies</strong>
                </Link>
                <Link to="/user/requests" className="btn btn-warning">
                  <strong>Request</strong>
                </Link>
              </div>

            </div>
            <div className="row justify-content-center">
              <SearchComponent />
            </div>
          </div>
        ) : (
          // Render Login or Register button based on the current path
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                {/* <button className="btn btn-warning" > */}
                <strong>CONTACT SUPPORT</strong>
                {/* </button> */}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to={isLoginPage ? "/register" : "/login"}
              >
                {/* <button
                className={variant}
              > */}
                <strong className={variant}>
                  {isLoginPage ? "REGISTER" : "LOGIN"}
                </strong>
                {/* </button> */}
              </Link>
            </li>
          </ul>
        )}
      </header>
    </div>
  );
}

export default Header;
