import { createContext, useEffect, useReducer } from "react";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { user: action.payload };
    case "LOGOUT":
      return { user: null };
    default:
      return state;
  }
};

const updateLogoutTimer = () => {
  const expirationTime = localStorage.getItem("tokenExpiration");
  if (expirationTime) {
    const timeLeft = parseInt(expirationTime, 10) - Date.now();
    if (timeLeft > 0) {
      const hours = Math.floor(timeLeft / (60 * 60 * 1000));
      const minutes = Math.floor((timeLeft % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((timeLeft % (60 * 1000)) / 1000);
  
      document.getElementById("logout-timer").textContent = `Time: ${
        hours < 10 ? "0" : ""
      }${hours}:${
        minutes < 10 ? "0" : ""
      }${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    } else {
      document.getElementById("logout-timer").textContent = "Session expired";
    }
  } else {
    document.getElementById("logout-timer").textContent = "Session expired";
  }
  
};

const checkTokenExpiration = (dispatch) => {
  const expirationTime = localStorage.getItem("tokenExpiration");
  if (expirationTime && Date.now() > parseInt(expirationTime, 10)) {
    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("tokenExpiration");
    console.log("Session expired please login again");
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      dispatch({ type: "LOGIN", payload: user });
      const intervalId = setInterval(() => {
        checkTokenExpiration(dispatch);
        updateLogoutTimer();
      }, 1000);
      return () => clearInterval(intervalId); // Clean up interval on unmount
    }
  }, []);

  // console.log('AuthContext state: ', state);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};



