import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import Register from "./Pages/Register.js";
import Login from "./Pages/Login.js";
import Home from "./Pages/Home.js";
import FreeComponent from "./Component/FreeRoute.js";
import ProtectedRoute from "./Routes/ProtectedRoute";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import Contact from "./Pages/Contact.js";
import ForgotPassword from "./Pages/ForgotPassword";
import NotFound from './Component/NotFound.js';
import {useAuthContext} from './hooks/useAuthContext.js'
import "./App.css";
import { StateContextProvider } from './Context/StateContext.js';
import DelayedNavigation from './Component/DelayedNavigation.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer} from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import FeatureRequests from './Pages/FeatureRequests.js';

export const config = {
  endpoint: process.env.REACT_APP_BACKEND
  };

function App() {
  const {user} = useAuthContext();

  return (

    <BrowserRouter>
    <GoogleOAuthProvider clientId="898179256677-a00r5gm6g64q86o2r8as9uoknkihbsin.apps.googleusercontent.com">
    <StateContextProvider>
    <Header/>

      <Routes>
        <Route exact path="*" element={<NotFound/>} />
        <Route exact path='/user' element={user ? <Navigate to="/user/allmovies" /> : <Navigate to="/login" />} />
        <Route exact path='/user/*' element={user ? <ProtectedRoute/> : <Navigate to="/login" />} />
        <Route exact path='/home' element={!user ? <Home /> : <Navigate to="/user/allmovies" />}/>
        <Route exact path='/' element={!user ? <Home /> : <Navigate to="/user/allmovies" />}/>
        <Route path="/register" exact element={!user ? <Register /> : <Navigate to="/user/allmovies" />} />
        <Route path="/login" exact element={!user ? <Login /> : <DelayedNavigation />} />
        <Route path="/forgotpassword" exact element={<ForgotPassword />} />
        <Route path="/free" exact element={<FreeComponent/>} />
        <Route path="/contact" exact element={<Contact/>} />
        <Route path="/features" exact element={<FeatureRequests/>} />
      </Routes>

      <ToastContainer />
      <Footer/>
      </StateContextProvider>
      </ GoogleOAuthProvider>
      </BrowserRouter>

  );
}

export default App;