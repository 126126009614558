import React, { useState, useEffect, useRef } from "react";
import Hls from "hls.js";
import { useLocation } from "react-router-dom"; //Add useNavigate for add automatic navigation
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import {
  deleteWatchHistoryItem,
  updateHistoryInLocalStorage,
} from "../utils/getAllHistoryItems";
import { useAuthContext } from "../hooks/useAuthContext";
import { getRandomMovieSuggestions } from "../utils/getRandomMovieSuggestions";
import { useStateContext } from "../hooks/StateContextProvider";

const HlsPlayer = () => {
  const videoRef = useRef(null);
  const [suggestions, setSuggestions] = useState(false); // State to control the display of the movie title
  const [randomMovie, setRandomMovie] = useState(); // State to control the display of the movie title
  const { movieDatabase } = useStateContext();
  // const navigate = useNavigate();

  const {
    url,
    title,
    episode,
    path,
    overview,
    genre,
    rating,
    year,
    genres,
    summary,
    Items,
  } = useLocation().state;
  const { user } = useAuthContext();

  const genreID = {
    28: "Action",
    12: "Adventure",
    16: "Animation",
    35: "Comedy",
    80: "Crime",
    99: "Documentary",
    18: "Drama",
    10751: "Family",
    14: "Fantasy",
    36: "History",
    27: "Horror",
    10402: "Music",
    9648: "Mystery",
    10749: "Romance",
    878: "Science Fiction",
    10770: "TV Movie",
    53: "Thriller",
    10752: "War",
    37: "Western",
  };

  const videoContainerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [volume, setVolume] = useState(1); // Volume range from 0 to 1
  const [progress, setProgress] = useState(0); // Current video progress (percentage)
  const [isControlsVisible, setIsControlsVisible] = useState(false); // Track control visibility
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const [isRemotePlaybackAvailable, setIsRemotePlaybackAvailable] =
    useState(false);
  const [isRemoteConnected, setIsRemoteConnected] = useState(false);
  const [hlsInstance, setHlsInstance] = useState(null);
  const [qualityLevels, setQualityLevels] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState(-1); // -1 for auto

  useEffect(() => {
    const video = videoRef.current;

    if ("remote" in video) {
      setIsRemotePlaybackAvailable(true);

      const handleRemoteConnect = () => {
        setIsRemoteConnected(true);
        console.log("Connected to remote playback");
      };

      const handleRemoteDisconnect = () => {
        setIsRemoteConnected(false);
        console.log("Disconnected from remote playback");
      };

      // Add event listeners
      video.remote.addEventListener("connect", handleRemoteConnect);
      video.remote.addEventListener("disconnect", handleRemoteDisconnect);

      return () => {
        // Clean up event listeners
        video.remote.removeEventListener("connect", handleRemoteConnect);
        video.remote.removeEventListener("disconnect", handleRemoteDisconnect);
      };
    }
  }, []);

  // Request remote playback
  const startRemotePlayback = () => {
    const video = videoRef.current;
    if (video.remote && !isRemoteConnected) {
      video.remote.prompt().catch((error) => {
        console.error("Error during remote playback:", error);
      });
    }
  };

  // Show loading spinner on video load/buffering
  useEffect(() => {
    const handleWaiting = () => setIsLoading(true);
    const handleCanPlay = () => setIsLoading(false);
    const handlePlaying = () => setIsLoading(false);

    const video = videoRef.current;
    video.addEventListener("waiting", handleWaiting); // Video is buffering
    video.addEventListener("canplay", handleCanPlay); // Video can start playing
    video.addEventListener("playing", handlePlaying); // Video started playing

    return () => {
      video.removeEventListener("waiting", handleWaiting);
      video.removeEventListener("canplay", handleCanPlay);
      video.removeEventListener("playing", handlePlaying);
    };
  }, []);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Enter fullscreen
      if (videoContainerRef.current.requestFullscreen) {
        videoContainerRef.current.requestFullscreen();
      } else if (videoContainerRef.current.mozRequestFullScreen) {
        /* Firefox */
        videoContainerRef.current.mozRequestFullScreen();
      } else if (videoContainerRef.current.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        videoContainerRef.current.webkitRequestFullscreen();
      } else if (videoContainerRef.current.msRequestFullscreen) {
        /* IE/Edge */
        videoContainerRef.current.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  // Play/Pause functionality
  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Handle video progress update
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    setProgress((currentTime / duration) * 100);
  };

  // Handle scrubbing (seeking through the video)
  const handleSeek = (event) => {
    const duration = videoRef.current.duration;
    const newTime = (event.target.value / 100) * duration;
    videoRef.current.currentTime = newTime;
    setProgress(event.target.value);
  };

  // Handle volume change
  const handleVolumeChange = (event) => {
    const newVolume = event.target.value / 150;
    videoRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  // Detect when fullscreen mode changes
  const handleFullscreenChange = () => {
    setIsFullscreen(
      document.fullscreenElement === videoContainerRef.current ||
        document.webkitFullscreenElement === videoContainerRef.current ||
        document.mozFullScreenElement === videoContainerRef.current ||
        document.msFullscreenElement === videoContainerRef.current
    );
  };

  useEffect(() => {
    // Listen for fullscreen changes
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    // Clean up the event listeners
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  const filterGenres = (genre) => {
    let data = "";
    for (const key in genre) {
      const id = genre[key];
      if (genreID.hasOwnProperty(id)) {
        data += " *";
        data += genreID[id];
      }
    }
    return data;
  };

  useEffect(() => {
    const summaryElement = document.getElementById("summary-text");
    if (summaryElement) {
      summaryElement.innerHTML = summary;
    }
  }, [summary]);

  useEffect(() => {
    const video = videoRef.current;
    const hls = new Hls();

    // Get the saved playback data for the user from localStorage
    const savedData = JSON.parse(localStorage.getItem(user.id));

    if (savedData) {
      // Find the specific movie data using its title
      const updatedTitle = episode ? title + " " + episode : title;

      const movieData = savedData.find((movie) => movie.title === updatedTitle);

      if (movieData && movieData.currentTime) {
        // Set the video current time to the saved playback time for that movie
        video.currentTime = parseFloat(movieData.currentTime);
      }
    }

    if (Hls.isSupported()) {
      setHlsInstance(hls);
      hls.attachMedia(video);
      hls.loadSource(`https://s5o2.c20.e2-7.dev/hlsbucket/${url}`);

      // Listen for manifest parsed event
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        const levels = hls.levels.map((level, index) => ({
          index: index,
          resolution: level.height + "p",
          bitrate: level.bitrate,
        }));
        setQualityLevels(levels);
        console.log("Manifest parsed, ready to play.");
        video.play();
        // Optionally, you could play here if it's appropriate for your use case
        setRandomMovie(
          getRandomMovieSuggestions(
            movieDatabase.length ? movieDatabase : Items
          )
        );
      });

      // Assign hls instance to video object
      video.hls = hls;
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = `https://s5o2.c20.e2-7.dev/hlsbucket/${url}`;
    }

    // Save the current playback time periodically
    const savePlaybackPosition = () => {
      // localStorage.setItem(user.id, JSON.stringify([{"title":title, "playback": video.currentTime, "currentTime":new Date() }]));
      updateHistoryInLocalStorage(user.id, title, video.currentTime, episode);
      // localStorage.setItem(`playback_${title}`, video.currentTime, new Date());
      // const playbackItems = {key:`playback_${title}`, playbackTime:video.currentTime}
      // updateHistoryItemsInDatabase(user.accessToken, playbackItems);
    };

    // Function to check if there are 10 minutes or less remaining
    const checkRemainingTime = () => {
      const remainingTime = video.duration - video.currentTime;
      if (remainingTime <= 240) {
        // 240 seconds = 4 minutes
        setSuggestions(true);
      }
      if (remainingTime > 600) {
        // 600 seconds = 10 minutes
        setSuggestions(false);
      }
    };

    video.addEventListener("timeupdate", savePlaybackPosition);
    video.addEventListener("timeupdate", checkRemainingTime); // Add event listener for remaining time check

    return () => {
      video.removeEventListener("timeupdate", savePlaybackPosition);

      // Optionally, you can remove the playback position when
      video.addEventListener("ended", () => {
        deleteWatchHistoryItem(user, title);
        // playNextVideo();
      });
    };
  }, [title, url, user, episode, Items, movieDatabase]);

  // Function to handle quality switch
  const handleQualityChange = (event) => {
    const selectedLevel = parseInt(event.target.value, 10);
    setSelectedQuality(selectedLevel);
    if (selectedLevel === -1) {
      // Auto mode
      hlsInstance.currentLevel = -1;
    } else {
      // Set to specific quality
      hlsInstance.currentLevel = selectedLevel;
    }
  };

  const getEpisode = (item) => {
    let parts = item.split("/");
    let lastPart = parts[parts.length - 2]; // Access the last element of the array using parts.length - 1
    let episodeInfoWithExtension =
      lastPart.split(" ")[lastPart.split(" ").length - 1]; // Access the last element after splitting by ' '
    // let episodeInfoWithoutExtension = episodeInfoWithExtension.split(".")[0]; // Remove the file extension
    return episodeInfoWithExtension;
  };

  useEffect(() => {
    let timeout;

    const showControls = () => {
      setIsControlsVisible(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsControlsVisible(false);
      }, 3000); // Hide controls after 3 seconds of inactivity
    };

    const handleUserInteraction = () => {
      showControls();
    };

    // Store ref in a local variable
    const videoContainer = videoContainerRef.current;

    if (videoContainer) {
      videoContainer.addEventListener("mousemove", handleUserInteraction);
      videoContainer.addEventListener("touchstart", handleUserInteraction);
    }

    return () => {
      if (videoContainer) {
        videoContainer.removeEventListener("mousemove", handleUserInteraction);
        videoContainer.removeEventListener("touchstart", handleUserInteraction);
      }
      clearTimeout(timeout);
    };
  }, []);

  function NextEpisodeLink() {
    const currentIndex = path.indexOf(url);
    const totalEpisodes = path.length;
    const renderNextEpisode = currentIndex + 1 < totalEpisodes;

    return (
      renderNextEpisode && (
        <Link
          to={"/user/player"}
          style={{ textDecoration: "none" }}
          state={{
            url: path[currentIndex + 1],
            title: title,
            path: path,
            episode: getEpisode(path[currentIndex + 1]),
            rating: rating,
            genres: genres,
            year: year,
            summary: overview,
          }}
        >
          <h4 id="simple-list-item-1">
            <div className="card text-wrap" style={{ width: "15rem" }}>
              <div className="card-body">
                Watch {getEpisode(path[currentIndex + 1])}
              </div>
            </div>
          </h4>
        </Link>
      )
    );
  }

  // isMobile is passed as a prop or tracked using a screen size detection hook
  function MovieSuggestion() {
    return randomMovie ? (
      <Link
        to={"/user/player"}
        state={{
          url: randomMovie.path[0],
          title: randomMovie.title,
          overview: randomMovie.overview,
          genre: randomMovie.genreID,
          rating: randomMovie.rating,
          Items: Items,
        }}
      >
        {/* Movie Image */}
        <img
          className="random-suggestion-image"
          src={randomMovie.img}
          alt={randomMovie.title}
        />

        {/* Play Button */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            // transform: "translate(-50%, -50%)", // Center the play button
            opacity: "0.5",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-play-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
          </svg>
        </div>
      </Link>
    ) : null;
  }

  return (
    <div style={{ justifyContent: "center", marginBottom: "400px" }}>
      <h1
        variant="h4"
        style={{ margin: "15px", justifyContent: "center" }}
        className="text-white"
      >
        {title} {episode ? episode : null}
      </h1>
      <div
        className="video-container"
        // style={{ width:"100%", height:"auto" }}
        ref={videoContainerRef}
      >
        <video
          ref={videoRef}
          controls={false} // Hide default controls to use custom ones
          onTimeUpdate={handleTimeUpdate} // Update progress as the video plays
          // width="100%"
          className={isFullscreen ? "fullscreen-video" : "desktop-video"}
        />

        {suggestions &&
          !isLoading && ( // Display movie title when there are 10 minutes left
            <div
              className="overlay-image"
              // style={isMobile ?  suggestionStyleMobile : suggestionStyle}
            >
              {episode ? <NextEpisodeLink /> : <MovieSuggestion />}
            </div>
          )}

        {/* Show Spinner when video is loading */}
        {isLoading && <div className="spinner"></div>}

        {/* Remote Playback Button */}
        {isRemotePlaybackAvailable && (
          <button
            onClick={startRemotePlayback}
            className="remote-playback-button"
          >
            {isRemoteConnected ? (
              "Connected to Remote"
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-cast"
                viewBox="0 0 16 16"
              >
                <path d="m7.646 9.354-3.792 3.792a.5.5 0 0 0 .353.854h7.586a.5.5 0 0 0 .354-.854L8.354 9.354a.5.5 0 0 0-.708 0" />
                <path d="M11.414 11H14.5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h3.086l-1 1H1.5A1.5 1.5 0 0 1 0 10.5v-7A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-2.086z" />
              </svg>
            )}
          </button>
        )}

        {/* Custom Controls */}
        <div
          className={`controls ${
            isControlsVisible ? "show-controls" : "hide-controls"
          }`}
        >
          {/* Play/Pause Button */}
          <div onClick={togglePlayPause} className="play-pause-button">
            {isPlaying ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-pause-fill"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5m5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-play-fill"
                viewBox="0 0 16 16"
              >
                <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
              </svg>
            )}
          </div>

          {/* Seek Bar */}
          <input
            type="range"
            className="seek-bar"
            value={progress}
            onChange={handleSeek}
          />

          {/* Volume Control */}
          <input
            type="range"
            className="volume-control"
            min="0"
            max="150"
            value={volume * 150}
            onChange={handleVolumeChange}
          />

          {/* Dropdown to select quality */}
          {qualityLevels.length > 0 && (
            <select
              value={selectedQuality}
              onChange={handleQualityChange}
              className="quality-selector"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-gear-wide"
                viewBox="0 0 16 16"
              >
                <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z" />
              </svg>
              <option value="-1">Auto</option>
              {qualityLevels.map((level) => (
                <option key={level.index} value={level.index}>
                  {level.resolution} ({Math.round(level.bitrate / 1000)} kbps)
                </option>
              ))}
            </select>
          )}

          {/* Fullscreen Toggle Button */}
          <div onClick={toggleFullscreen} className="fullscreen-button">
            {isFullscreen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="white"
                className="bi bi-arrows-fullscreen"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707m0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="white"
                className="bi bi-fullscreen-exit"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5m5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5M0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5m10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0z" />
              </svg>
            )}
          </div>
        </div>
      </div>
      <p
        variant="h5"
        style={{ margin: "15px", justifyContent: "center" }}
        className="text-white"
      >
        {overview}
      </p>
      <div>
        <h6
          variant="h4"
          style={{ margin: "15px", justifyContent: "center" }}
          className="text-white"
        >
          {filterGenres(genre)}
        </h6>
        <h6
          variant="h4"
          style={{ margin: "15px", justifyContent: "center" }}
          className="text-white"
        >
          Movie Rating: {rating ? parseFloat(rating.toFixed(1)) : 0}
        </h6>
      </div>
      {/* Scroll Bar */}
      {path ? (
        <div className="row-2 ms-1 d-flex col-md-8 col-sm-12">
          <div className="scrollmenu ">
            {path.map((item, index) => (
              <Link
                to={"/user/player"}
                key={index}
                style={{ textDecoration: "none" }}
                state={{
                  url: item,
                  title: title,
                  path: path,
                  episode: getEpisode(item),
                  rating: rating,
                  genres: genres,
                  year: year,
                  summary: overview,
                }}
              >
                <h4 id="simple-list-item-1">
                  <div className="card text-wrap" style={{ width: "15rem" }}>
                    <div className="card-body">Watch {getEpisode(item)}</div>
                  </div>
                </h4>
              </Link>
            ))}
          </div>
        </div>
      ) : null}
      {summary ? (
        <Col lg={6} xs={12}>
          <p id="summary-text" className="text-white ms-3 mt-1"></p>
        </Col>
      ) : null}
    </div>
  );
};

export default HlsPlayer;
